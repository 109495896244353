// api.ts
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import authedAxios from "./auth-axios";

export const analytics_chat_label = "analytics";

export interface Message {
    state_id: string;
    msg_id: string;
    user: "user" | "bot" | "system";
    text: string;
    timestamp: string;
}

export interface Conversation {
    _id: string;
    created_at: string;
    last_updated: string;
    name: string;
    type: string;
    user_id: string;
    course_id: string;
    messages: Message[];
    relevant_week_numbers: string[];
}

export const loadConversations = async (user_id: string, course_id?: string): Promise<Conversation[]> => {
    try {
        const queryParamStr = course_id ? `?course_id=${course_id}&user_id=${user_id}` : `?user_id=${user_id}`;
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/conversations${queryParamStr}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
};

export const newConversation = async (course_id: string, user_id: string, type: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/conversations/new`, {
            course_id,
            user_id,
            type
        });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}


export const deleteConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.delete(`${process.env.REACT_APP_BACKEND_URL}/conversations/${conversation_id}`);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const getConversation = async (conversation_id: string): Promise<Conversation> => {
    try {
        const response = await authedAxios.get(`${process.env.REACT_APP_BACKEND_URL}/conversations/${conversation_id}`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}:`, error);
        throw error;
    }
}

export const likeMessage = async (conversation_id: string, botMsgContent: string): Promise<void> => {
    try {
        await authedAxios.patch(`${process.env.REACT_APP_BACKEND_URL}/conversations/${conversation_id}/like-msg`, {
            msg_text: botMsgContent
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}

export const dislikeMessage = async (conversation_id:string, botMsgContent: string): Promise<void> => {
    try {
        await authedAxios.patch(`${process.env.REACT_APP_BACKEND_URL}/conversations/${conversation_id}/dislike-msg`, {
            msg_text: botMsgContent
        });
    } catch (error) {
        console.error('Error Liking Message:', error);
        throw error;
    }
}
