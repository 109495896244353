import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import authedAxios from "./auth-axios";

export interface SatisfactionTableDataType {
    key: string;
    userName: string;
    satisfaction: number | string;
}


export interface SatisfactionPlotResponse {
    user_id: string;
    user_name: string;
    satisfaction: number | null;
}

export interface RagCoverageTableDataType {
    key: string;
    userName: string;
    coverage: number | string;
}

export interface RagCoveragePlotResponse {
    user_id: string;
    user_name: string;
    coverage: number | null;
}

export interface AvgMessagePerDayTableDataType {
    key: string;
    userName: string;
    avg_messages_per_day: number | string;
}

export interface AvgMessagePerDayPlotResponse {
    user_id: string;
    user_name: string;
    avg_messages_per_day: number | null;
}

export interface SourceCountTableDataType {
    key: string;
    userName: string;
    source_counts: Record<string, number>;
}

export interface SourceCountPlotResponse {
    user_id: string;
    user_name: string;
    source_counts: Record<string, number>;
}

export type QueryPCADataPoint = {
    text: string,
    embedding: number[]
}

export type QueryData = {
    queries: QueryPCADataPoint[];
    topics: QueryPCADataPoint[];
}

export type QueryPCAData = {
    queriesPCA: number[][];
    topicsPCA: number[][];
}
export type StudentCourseQueryCount = {
    student_id: string,
    student_name: string,
    query_count: number

}

export const fetchSatisfactionData = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<SatisfactionPlotResponse[]> => {
    try {
        const response = await authedAxios.post<SatisfactionPlotResponse[]>(`${process.env.REACT_APP_BACKEND_URL}/analytics/${courseID}/data/satisfaction`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching satisfaction data:", error);
        throw error;
    }
};

export const fetchCoverageData = async (
    courseID: string,
    timeframe: string | string[],
    relevant_weeks: string[],
    enrolled_student_ids: string[]
): Promise<RagCoveragePlotResponse[]> => {
    try {
        const response = await authedAxios.post<RagCoveragePlotResponse[]>(`${process.env.REACT_APP_BACKEND_URL}/analytics/${courseID}/data/ragcoverage`, {
            timeframe: timeframe,
            relevant_weeks: relevant_weeks,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching coverage data:", error);
        throw error;
    }
};

export const fetchAvgMessagePerDayData = async (courseID: string,
    enrolled_student_ids: string[]
): Promise<AvgMessagePerDayPlotResponse[]> => {
    try {
        const response = await authedAxios.post<AvgMessagePerDayPlotResponse[]>(`${process.env.REACT_APP_BACKEND_URL}/analytics/${courseID}/data/avg_message_per_day`, {
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching average messages per day data:", error);
        throw error;
    }
};

export const fetchSourceCountData = async (
    courseID: string,
    timeframe: string | string[],
    enrolled_student_ids: string[]
): Promise<SourceCountPlotResponse[]> => {
    try {
        const response = await authedAxios.post<SourceCountPlotResponse[]>(`${process.env.REACT_APP_BACKEND_URL}/analytics/${courseID}/data/source_counts`, {
            timeframe: timeframe,
            enrolled_student_ids: enrolled_student_ids
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching source count data:", error);
        throw error;
    }
};


export const getQueryData = async (
    id: string,
    timeframe: string | string[]
): Promise<QueryData> => {
    try {
        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/analytics/${id}/data/query-pca-data`, {
            timeframe: timeframe
        });
        return response.data;
    } catch (error) {
        const user = getUserDataFromLocalStorage();
        console.error(`Error fetching ${user.config.course_terminology.toLowerCase()}s analytics:`, error);
        throw error;
    }

}