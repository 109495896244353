import React from 'react';
import { Typography, Button, Space } from 'antd';

const { Title } = Typography;

interface FallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const FallbackComponent: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <Space direction="vertical" align="center" style={{ width: '100%', height: '100vh', justifyContent: 'center' }}>
      <Title level={2}>Sorry for the inconvenience</Title>
      <Title level={4}>An unexpected error has occurred</Title>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </Space>
  );
};

export default FallbackComponent;