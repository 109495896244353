import { Link } from 'react-router-dom';
import { Container, FormContainer, Subtitle, Title, TopImages } from '../../components/session-styles';
import SignupForm from '../../components/signup/signup-form';
import { AbsoluteCenter } from '../../components/styles';
import { SunOutlined, SunFilled } from '@ant-design/icons';

const Signup = ({ loginCallback, isDarkMode, toggleTheme }) => {
    console.log("SignupScreen is rendering");
    return (
        <>
            <TopImages />
            <AbsoluteCenter>
                <Container>
                    <FormContainer>
                        <Title>Create an account</Title>
                        <Subtitle>Enter your email below to create your account</Subtitle>
                        <SignupForm loginCallback={loginCallback} />
                        <p className="mt-4 text-center">
                            Already have an account?{' '}
                            <Link to="/login" style={{ color: isDarkMode ? 'white' : 'black' }}>
                                Go to Login
                            </Link>
                        </p>
                    </FormContainer>

                    <div className="absolute bottom-4 right-4 flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-full ${isDarkMode ? 'bg-black hover:bg-gray-800' : 'bg-white hover:bg-gray-200'
                                } transition-colors border-none`}
                            aria-label="Toggle theme"
                        >
                            {isDarkMode ? (
                                <SunOutlined style={{ fontSize: '24px', color: 'white' }} />
                            ) : (
                                <SunFilled style={{ fontSize: '24px', color: 'black' }} />
                            )}
                            <p className={`mr-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>Switch theme</p>
                        </button>
                    </div>
                </Container>
            </AbsoluteCenter>
        </>
    );
};

export default Signup;