export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export function formatDate(dateString) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
}

export function getRandomDimColor() {
  const colors = [
    "#79aaf8",
    "#dd798d", 
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

export function preprocessLatex(text) {
  // holy fuck
  //   ## LaTeX Formulas
  // You can include LaTeX formulas in your Markdown like this:

  // - Inline formula: $E=mc^2$
  // - Displayed formula: \[ E=mc^2 \]
  // - Inline formula:
  //   \( F = G \frac{{m_1 m_2}}{{r^2}} \)
  // - Inline formula:
  //   \( F = G \frac{{m^1 m^2}}{{r^2}} \)
  // - Displayed formula: \[ E=mc^2 \]
  // - Displayed formula: \[ F = G \frac{{m_1 m_2}}{{r^2}} \]

  // ```latex
  // F = G \frac{{m_1 m_2}}{{r^2}}
  // ```
  return text.replace("\\[ ", () => "\n```latex\n")
    .replace(" \\]", () => "\n```")
    .replace("\\[", () => "\n```latex\n")
    .replace("\\]", () => "\n```")
    .replace("\\( ", () => "$")
    .replace(" \\)", () => "$")
    .replace("\\(", () => "$")
    .replace("\\)", () => "$")
    .replace(/\$(.*?)\$/g, (match) => {
      return match.replace(/_/g, "")
    })
}


export const extractVariables = (templateText) => {
  const variableRegex = /\{([^}]+)\}/g;
  let match;
  const variables = [];

  while (match = variableRegex.exec(templateText)) {
    variables.push(match[1]);
  }

  return variables;
};

export const getAnalyticsCopilotInitialMessage = () => {
  return `
  Hi, 

  I'm your Analytics Copilot! I'm here to help you understand, how your students chat with the materials. 
  
  You can ask me to do the following:
  
  - Motivation of a specific student 
  - Satisfaction of a specific student 
  - The intent behind the student's queries 
  - All of the above for all students together 
  
Please take care of spelling the student's names as recorded in my database. I struggle with that sometimes.

  Specify a start and end date in or provide me with a timeframe (f.e. this week or last 24 days).

  Feel free to ask away!
  `;
}

export const hexToRGBA = (hex, alpha = 1) => {
  let r = 0, g = 0, b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};