import { DislikeOutlined, LikeOutlined, CalendarOutlined, LoadingOutlined, QuestionCircleOutlined, UpCircleOutlined, UpCircleFilled } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Avatar, Button, Col, Divider, Input, Layout, Menu, MenuProps, Row, Tooltip, message } from "antd";
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { theme } from 'antd';

import MarkdownView, { ShowdownExtension } from "react-showdown";
// @ts-ignore
import showdownKatex from 'showdown-katex';
import { v4 as uuidv4 } from 'uuid';
import { Conversation, Message, dislikeMessage, getConversation, likeMessage } from "../../services/conversations";
import { Course, getCourse, Week, WeekMaterial } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { capitalize, preprocessLatex } from "../../utils/utils";
import authedAxios from "../../services/auth-axios";
import TemplateSider from "../../components/chat/TemplateSider";
import AssignmentSelectionOverlay from "../../components/chat/AssignmentSelectionOverlay";
import { NoMarginButton } from "../../components/basic/buttons";

const chatSiderWidth = "33vh";

const { Content } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const Container = styled(Content, {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100vh",
    // minHeight: "100vh",
    // width: "100vh",
    width: `calc(100vh - ${chatSiderWidth} / 2)`, // Using calc() to subtract chatSiderWidth from 100vh
    // maxHeight: "100vh",
    // maxWidth: "800px",
    margin: "0 auto",
    gap: "10px",
    padding: "2rem",
    boxSizing: "border-box",
})

const HeaderContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px", // Adjust padding as necessary
    borderBottom: "1px solid #e0e0e0", // Optional bottom border for separation
    minHeight: "60px", // Adjust as necessary for header height
    boxSizing: "border-box",
});

const SendContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center', // Centers children horizontally
    justifyContent: 'center', // Centers children vertically
    width: '80vh',
    maxHeight: '100px',
    flexShrink: 0,
    maxWidth: '100%',
    margin: '0 auto', // Center the container
})

const SendInput = styled(Input.TextArea, {
    borderRadius: '8px',
    // maxWidth: '50vh', // Ensure it doesn't exceed container width
    width: '80vh',
})

const MessagesContainer = styled("div", {
    width: '80vh',
    maxWidth: '100%',
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
});

const MessageBubbleContainer = styled("div", {
    flexShrink: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "10px",
    padding: "10px",

    "& #answer > :first-child ": {
        marginTop: "0 !important",
        paddingTop: "0 !important",
    },
})

const SendButton = styled(NoMarginButton, {
    position: "absolute",
    right: "0",
    bottom: "3px",
    zIndex: 1,
})

const LikeDislikeContainer = styled("div", {
    display: "flex",
    flexDirection: "row",
    gap: "0.5rem"
});

const Flex = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem"
})

const MessageBubble = ({ conversationId, msgId, user, text }: { conversationId: string, msgId: string, user: "user" | "bot" | "system", text: string }) => {
    const userData = getUserDataFromLocalStorage();
    const [logoData, setLogoData] = useState<string | undefined>(undefined)
    const [parsedText, setParsedText] = useState<string>(text)
    const [likeLoading, setLikeLoading] = useState<boolean>(false)
    const [dislikeLoading, setDislikeLoading] = useState<boolean>(false)


    useEffect(() => {

        // inlining this shit breaks one of the example formulas, idk why
        setParsedText(text =>
            preprocessLatex(text)
        )
    }, [text])

    useEffect(() => {
        setLogoData(userData.config.logo)
    }, [user, userData.config.logo])



    function handleLike() {
        setLikeLoading(true)
        likeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!")
            })
            .catch(err => {
                message.error("Error liking message: " + err);
                console.error(err);
            })
            .finally(() =>
                setLikeLoading(false)
            )
    }

    function handleDislike() {
        setDislikeLoading(true)
        dislikeMessage(conversationId, text)
            .then(() => {
                message.success("Thank you for the feedback!")
            })
            .catch(err => {
                message.error("Error disliking message: " + err);
                console.error(err);
            })
            .finally(() =>
                setDislikeLoading(false)
            )
    }

    return (<>
        <MessageBubbleContainer >
            <Avatar
                size="small"
                src={(user === "bot" || user === "system") && logoData}
                style={{
                    flexShrink: 0,
                    alignSelf: 'flex-start ',
                    marginTop: "-3px",
                    backgroundColor: user === "user" ? "orange" : "transparent"
                }}
            >{
                    user === "user" && userData.name[0]}
            </Avatar>
            <Flex>
                <b>{user === "user" ? "You" : "AI"}</b>
                <MarkdownView
                    id="answer"
                    markdown={parsedText}
                    options={{ tables: true, emoji: true }}
                    extensions={showdownKatex({
                        // Options for KaTeX rendering
                        displayMode: true,
                        throwOnError: false,
                        errorColor: '#ff0000',
                        delimiters: [
                            { left: "$$", right: "$$", display: true },
                            { left: "\\[", right: "\\)", display: true },
                            { left: "\\(", right: "\\)", display: true },
                            { left: "$", right: "$", display: false }
                        ]
                    }) as unknown as ShowdownExtension[]}
                />
                {user === "bot" && <>
                    <LikeDislikeContainer>
                        <Button loading={likeLoading} type="text" icon={<LikeOutlined />} onClick={() => handleLike()} />
                        <Button loading={dislikeLoading} type="text" icon={<DislikeOutlined />} onClick={() => handleDislike()} />
                    </LikeDislikeContainer>
                </>}
            </Flex>
        </MessageBubbleContainer>
    </>)
}

type ChatInterfaceProps = {
    type: string;
    endpointUrl: string;
    initialMessage?: string;
};

const ChatInterface = ({ type: typeSpecifier, endpointUrl: endpointUrlSpecifier, initialMessage: initialMessageSpecifier }: ChatInterfaceProps) => {
    const { chatID } = useParams();
    const userData = getUserDataFromLocalStorage();
    const [messages, setMessages] = useState<Message[]>([])
    const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false)
    const [input, setInput] = useState<string>("")
    const [shiftPressed, setShitPressed] = useState<boolean>(false)
    const [conversation, setConversation] = useState<Conversation | undefined>(undefined)

    const [course, setCourse] = useState<Course | undefined>(undefined)

    const [weekMenuItems, setWeekMenuItems] = useState<MenuItem[]>([]);
    const [selectedWeeks, setSelectedWeeks] = useState<number[]>([]);

    const [groupMenuItems, setGroupMenuItems] = useState<MenuItem[]>([]);
    const [selectedGroups, setSelectedGroup] = useState<string[]>([]);

    const [materialMenuItems, setMaterialMenuItems] = useState<MenuItem[]>([]);
    const [selectedMaterials, setSelectedMaterials] = useState<string[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [overlayVisible, setOverlayVisible] = useState<boolean>(false); // State for overlay visibility
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { useToken } = theme;
    const { token } = useToken();

    const sider_tooltip = <span>{`Use the templates to quickly use your favourte prompts. Filter the response content by ${userData.config.course_terminology.toLowerCase()} ${userData.config.module_terminology.toLowerCase()}.`}</span>;
    const main_tooltip = (
        <span>
            What can you do with this AI? <br /><br />
            {`You can chat with all the documents uploaded by your educators to the selected ${userData.config.module_terminology.toLowerCase()}s. If no ${userData.config.module_terminology.toLowerCase()} is selected, you are chatting to all the information available to the system. `}<br />
            {`Answers will provide the source, including a page number or timestamp, the relevant ${userData.config.module_terminology.toLowerCase()}, and a relevancy score. The relevancy score may not align with your perspective as it is an auto-calculated score. `}<br /><br />
            You can further ask the AI: <br />
            <ul>
                <li>{`for a summary of the selected ${userData.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`for a list of the documents in the selected ${userData.config.module_terminology.toLowerCase()}s.`}</li>
                <li>{`the names of the educators of the ${userData.config.course_terminology.toLowerCase()}.`}</li>
            </ul><br />
            Do you think a function is missing? Reach out to us using the feedback button in the bottom left. We are eager to improve!
        </span>
    );

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        setIsLoadingMessages(true)
        getConversation(chatID!).then(c => {
            setConversation(c)
        })
    }, [chatID])


    useEffect(() => {
        if (!conversation) return;
        getCourse(conversation.course_id!).then((course) => {
            setIsLoadingMessages(true);
            setCourse(course);
            setIsLoadingMessages(false);
        });
    }, [conversation])

    useEffect(() => {
        if (!conversation) return;
        setIsLoadingMessages(true);
        let initialMessage = {
            state_id: uuidv4(),
            msg_id: uuidv4(),
            user: "system",
            text: initialMessageSpecifier ? initialMessageSpecifier : `Hello ${userData.name}, how are you?\n\nWhat would like to learn today?`,
            timestamp: new Date().toISOString()
        } as Message

        setMessages(
            (conversation.messages && conversation.messages.length > 0)
                ? conversation.messages
                : [initialMessage]
        );
        setIsLoadingMessages(false);
    }, [conversation])

    useEffect(() => {
        if (course) {
            const relevantWeekItems = course.weeks.map((week, index) => ({
                label: `${capitalize(userData.config.module_terminology)} ${week.number}`,
                key: week.number,
            }));

            // Prepend "Select All" to the top if there are at least two weeks
            if (relevantWeekItems.length >= 2) {
                relevantWeekItems.unshift({ // Use unshift to add at the beginning
                    label: 'Select All',
                    key: 0, // Key for "Select All"
                });
            }

            let newWeekMenuItems = [{
                label: `Select ${capitalize(userData.config.module_terminology)}s`,
                key: 'select-modules',
                children: [
                    ...relevantWeekItems,
                ],
                icon: <CalendarOutlined />,
            }];

            if (relevantWeekItems.length > 0) {
                if (conversation?.relevant_week_numbers && conversation.relevant_week_numbers.length > 0) {
                    setSelectedWeeks(conversation.relevant_week_numbers.map(week => parseInt(week)))
                } else {
                    // Select the last week by default    
                    setSelectedWeeks([course!.weeks.length]); // selectedWeeks is 1-indexed
                }
                generateMenuItems([course!.weeks[course!.weeks.length - 1]]);
            } else {
                setSelectedWeeks([]);
            }
            setWeekMenuItems(newWeekMenuItems);
        }
    }, [course]);

    const onWeekSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedWeeks = [...selectedWeeks];
        const clickedWeekNumber = parseInt(e.key);

        if (clickedWeekNumber === 0) {
            // "Select All" logic
            if (course && updatedSelectedWeeks.length === course.weeks.length) {
                // If all weeks are already selected, deselect all
                updatedSelectedWeeks = [];
            } else {
                // Else, select all weeks
                updatedSelectedWeeks = course ? course.weeks.map((_, index) => index + 1) : [];
            }
        } else {
            // Individual week selection logic
            if (updatedSelectedWeeks.includes(clickedWeekNumber)) {
                // If already selected, remove from selection
                updatedSelectedWeeks = updatedSelectedWeeks.filter(week => week !== clickedWeekNumber);
            } else {
                // If not selected, add to selection
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        if (updatedSelectedWeeks.length === 0 && course!.weeks.length > 1) {
            if (clickedWeekNumber === 0) {
                // If all weeks are deselected via Select All, select the last week
                updatedSelectedWeeks.push(course!.weeks.length);
            } else {
                // If no weeks are selected, select the clicked week
                updatedSelectedWeeks.push(clickedWeekNumber);
            }
        }

        setSelectedWeeks(updatedSelectedWeeks); // Update state
        updateRelevantWeekNumbers(updatedSelectedWeeks); // Call backend with the updated list
        // Update group menu items
        const selectedWeekData = course!.weeks.filter(week => updatedSelectedWeeks.includes(week.number));

        generateMenuItems(selectedWeekData);
    };

    function generateMenuItems(selectedWeekData: Week[]) {
        const uniqueGroups = Array.from(new Set(selectedWeekData.flatMap(week =>
            week.materials ? week.materials.map(material => material.group) : []
        )));

        const newGroupMenuItems = [{
            label: `Select Groups`,
            key: 'select-groups',
            children: uniqueGroups.map(group => ({
                label: `Group ${group}`,
                key: group,
            })),
            icon: <CalendarOutlined />,
        }];
        setGroupMenuItems(newGroupMenuItems);

        const allMaterials = selectedWeekData.flatMap(week => week.materials || []);
        const uniqueMaterialsMap = new Map(allMaterials.map(material => [material.file_id, material]));

        const newMaterialMenuItems = [{
            label: `Select Materials`,
            key: 'select-materials',
            children: Array.from(uniqueMaterialsMap.values()).map(material => ({
                label: material.name,
                key: material.file_id,
            })),
            icon: <CalendarOutlined />,
        }];

        setMaterialMenuItems(newMaterialMenuItems);
    };

    const onGroupSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedGroups = [...selectedGroups];
        const clickedGroup = e.key;

        if (updatedSelectedGroups.includes(clickedGroup)) {
            // If already selected, remove from selection
            updatedSelectedGroups = updatedSelectedGroups.filter(group => group !== clickedGroup);
        } else {
            // If not selected, add to selection
            updatedSelectedGroups.push(clickedGroup);
        }

        setSelectedGroup(updatedSelectedGroups); // Update state
        updateRelevantGroups(updatedSelectedGroups); // Call backend with the updated list
    };

    const onMaterialSelectionClick: MenuProps['onClick'] = (e) => {
        let updatedSelectedMaterials = [...selectedMaterials];
        const clickedMaterialFileId = e.key;

        if (updatedSelectedMaterials.includes(clickedMaterialFileId)) {
            // If already selected, remove from selection
            updatedSelectedMaterials = updatedSelectedMaterials.filter(fileId => fileId !== clickedMaterialFileId);
        } else {
            // If not selected, add to selection
            updatedSelectedMaterials.push(clickedMaterialFileId);
        }

        setSelectedMaterials(updatedSelectedMaterials); // Update state
        updateRelevantFileIds(updatedSelectedMaterials); // Call backend with the updated list
    };


    const handleKeydown = (e: any) => {
        const key = e.which || e.keyCode;
        if (key === 16) {
            setShitPressed(true);
        } else {
            if (key !== 13) {
                setShitPressed(false);
            }
        }

        if (!shiftPressed && key === 13 && !isLoadingMessages) {
            sendMessage();
        }
    };

    const handleSetMessageText = (text: string) => {
        setInput(text);  // Set the input state which is bound to the message input field
    };

    function sendMessage() {
        if (input === "") return;

        let new_msg_id = uuidv4()
        const newUserMessage = { state_id: uuidv4(), msg_id: new_msg_id, user: "user", text: input, timestamp: new Date().toISOString() } as Message;

        // You may want to clear the input field here
        setInput("");
        setIsLoadingMessages(true);

        setMessages(prevMessages => [...prevMessages, newUserMessage]);

        // Get the sources with Bearer auth header
        const token = localStorage.getItem('access_token');
        if (!token) {
            throw new Error("No access token available");
        }
        let buffer = '';
        const enumerationRegex = /(\d+)\.\s/g;

        const processText = (text: string) => {
            buffer += text;
            let match;
            let lastIndex = 0;
            let processedText = '';

            while ((match = enumerationRegex.exec(buffer)) !== null) {
                processedText += buffer.slice(lastIndex, match.index);
                processedText += match[0];
                lastIndex = enumerationRegex.lastIndex;
            }

            if (lastIndex > 0) {
                updateOrAddMessage(new_msg_id, processedText);
                buffer = buffer.slice(lastIndex);
            }
        };

        (async () => {
            try {
                const textDecoder = new TextDecoder();
                const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/${endpointUrlSpecifier}/${chatID}/chat?user_query=${encodeURIComponent(input)}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (res.body) {
                    const reader = res.body.getReader();
                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) break;
                        const text_value = textDecoder.decode(value, { stream: true });
                        processText(text_value);
                    }
                } else {
                    throw new Error("No body");
                }

                if (buffer.length > 0) {
                    updateOrAddMessage(new_msg_id, buffer);
                }
            } catch (error: any) {
                console.error('Error:', error);
                setError(error.message); // Update error state when an error occurs
            } finally {
                setIsLoadingMessages(false)
            }
        })()
    }

    function updateOrAddMessage(new_msg_id: String, new_token: String) {
        // This function updates the last message without adding a new one. This facilitates streaming in the UI.
        setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            const prevMessagesExceptLast = prevMessages.slice(0, prevMessages.length - 1);
            const updatedMessages = prevMessages.length > 0 && lastMessage.user === "bot"
                ? [...prevMessagesExceptLast, { state_id: uuidv4(), msg_id: new_msg_id, user: 'bot', text: lastMessage.text + new_token, timestamp: new Date().toISOString() } as Message]
                : [...prevMessages, { state_id: uuidv4(), msg_id: new_msg_id, user: 'bot', text: new_token, timestamp: new Date().toISOString() } as Message];
            return updatedMessages;
        });
    }

    async function updateRelevantWeekNumbers(weekNumbers: number[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_week_numbers`,
                weekNumbers,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with relevant weeks");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantGroups(groups: string[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_groups`,
                groups,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with groups");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    async function updateRelevantFileIds(file_ids: string[]) {
        try {
            const response = await authedAxios.put(`${process.env.REACT_APP_BACKEND_URL}/conversations/${chatID}/update_relevant_file_ids`,
                file_ids,
            );

            if (response.status !== 200) {
                throw new Error("Failed to update conversation with file_ids");
            }

            console.log("Conversation updated successfully:", response.data);
        } catch (error) {
            console.error("Error updating conversation:", error);
        }
    }

    function handleShowAssignmentClick() {
        setOverlayVisible(true);
    }

    const handleWeekSelect = (weekNumber: number) => {
        const selectedWeek = course?.weeks.find(week => week.number === weekNumber);
        if (selectedWeek) {
            const newMessage: Message = {
                state_id: uuidv4(),
                msg_id: uuidv4(),
                user: "bot",
                text: selectedWeek.assignment || "No assignment for this week.",
                timestamp: new Date().toISOString()
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
        }
        setOverlayVisible(false);
    };

    return (<>
        <Layout style={{ height: '100vh' }}>
            <Layout.Content>
                <Container>
                    <MessagesContainer>
                        {course &&
                            <HeaderContainer>
                                <div style={{ width: '50%' }}>
                                    <h1 style={{ margin: 0, fontSize: '24px' }}>{course.name}</h1>
                                </div>
                                <div style={{
                                    width: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: "nowrap",
                                    maxWidth: "100%",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    padding: "0px 0px",
                                }}>
                                    <Button
                                        style={{
                                            flexShrink: 0,
                                            height: '100%',
                                            width: '80%'
                                        }}
                                        onClick={handleShowAssignmentClick}
                                    >
                                        Assignments
                                    </Button>
                                    <Tooltip placement="bottomRight" title={main_tooltip} overlayStyle={{ maxWidth: '50vh' }}>
                                        <Button
                                            icon={<QuestionCircleOutlined />}
                                            style={{
                                                flexShrink: 0,
                                                height: '100%',
                                                margin: 5,
                                                width: '20%',
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </HeaderContainer>
                        }
                        {messages.map((msg) => {
                            return (
                                <MessageBubble
                                    key={msg.state_id}
                                    conversationId={conversation!._id}
                                    msgId={msg.msg_id}
                                    user={msg.user}
                                    text={msg.text}
                                />
                            );
                        })}
                        <div ref={messagesEndRef} /> {/* Dummy div for scrolling */}
                    </MessagesContainer>
                    <SendContainer>
                        <div style={{ position: "relative" }}>
                            <SendInput size="large"
                                autoSize={{ minRows: 1, maxRows: 6 }}
                                placeholder="Send message..."
                                value={input}
                                onChange={e => setInput(e.target.value.replace(/^\s+/, ''))}
                                onKeyDown={e => handleKeydown(e)} />
                            <SendButton
                                data-testid="send-button"
                                size="large"
                                type="text"
                                disabled={isLoadingMessages || selectedWeeks.length === 0}
                                onClick={() => { sendMessage() }}
                                style={{ transition: 'background-color 0.3s ease', color: token.colorPrimary }}
                                onMouseEnter={(event) => {
                                    event.currentTarget.style.backgroundColor = 'transparent';
                                    event.currentTarget.style.color = token.colorPrimaryHover;
                                }}
                                onMouseLeave={(event) => {
                                    event.currentTarget.style.backgroundColor = 'transparent';
                                    event.currentTarget.style.color = token.colorPrimary;
                                }}
                            >
                                {isLoadingMessages
                                    ? <LoadingOutlined style={{ color: token.colorPrimary }} /> : input.length > 0
                                        ? <UpCircleFilled style={{ fontSize: '24px' }} />
                                        : <UpCircleOutlined style={{ fontSize: '24px' }} />}
                            </SendButton>
                        </div>
                    </SendContainer>
                </Container>
                <AssignmentSelectionOverlay
                    visible={overlayVisible}
                    weeks={course ? course.weeks : []}
                    onWeekSelect={handleWeekSelect}
                    onClose={() => setOverlayVisible(false)}
                />
            </Layout.Content>
            <Layout.Sider width={chatSiderWidth} style={{ overflow: 'hidden' }}>
                <Row justify="space-between">
                    <Col span={22} push={1}>
                        <div
                            style={{
                                textAlign: 'left',
                                width: '90%',
                                height: '100%',
                                border: 'none',
                                boxShadow: 'none',
                                color: 'inherit',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <h3>Side Action Bar</h3>
                        </div>
                    </Col>
                    <Col span={2}>
                        <Tooltip placement="left" title={sider_tooltip}>
                            <Button
                                icon={<QuestionCircleOutlined />}
                                style={{
                                    flexShrink: 0,
                                    height: '100%',
                                    border: 'none',
                                    padding: 0,
                                    width: '10%',
                                }}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                <TemplateSider onSetMessageText={handleSetMessageText} />
                <Divider
                    style={{ margin: 5 }}>
                </Divider>
                {course && course.weeks.length > 0 &&
                    (
                        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                            <Menu
                                style={{ maxHeight: '50vh', overflow: 'auto' }}
                                onClick={onWeekSelectionClick}
                                selectedKeys={selectedWeeks.map(week => week.toString())}
                                defaultOpenKeys={['select-modules']}
                                mode="inline"
                                items={weekMenuItems} />
                        </div>
                    )
                }
                {typeSpecifier === "content" && course && course.weeks.length > 0 && selectedWeeks.length > 0 &&
                    (
                        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
                            {userData.config.filter_by_group &&
                                <Menu
                                    style={{ maxHeight: '50vh', overflow: 'auto' }}
                                    onClick={onGroupSelectionClick}
                                    selectedKeys={selectedGroups.map(group => group.toString())}
                                    defaultOpenKeys={['select-groups']}
                                    mode="inline"
                                    items={groupMenuItems} />
                            }
                            {userData.config.filter_by_source &&
                                <Menu
                                    style={{ maxHeight: '50vh', overflow: 'auto' }}
                                    onClick={onMaterialSelectionClick}
                                    selectedKeys={selectedMaterials.map(material => material.toString())}
                                    defaultOpenKeys={['select-materials']}
                                    mode="inline"
                                    items={materialMenuItems} />
                            }
                        </div>
                    )
                }
            </Layout.Sider>
        </Layout>
    </>)
}

export default ChatInterface;