import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Modal, Table, Input } from 'antd';
import TemplateCreator, { Template, VariableDescription } from './template-creator';
import { getUserDataFromLocalStorage, setUserDataInLocalStorage } from '../../utils/useLocalStorage';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@stitches/react';
import authedAxios from '../../services/auth-axios';

const { Sider } = Layout;
const chatSiderWidth = "33vh";

const StyledButton = styled(Button, {
  display: 'flex',
  justifyContent: 'center',
  width: '96%',
  margin: 'auto',
});

interface TemplateSiderProps {
  onSetMessageText?: (text: string) => void;
}

interface VariableWithDescription extends VariableDescription {
  value: string;
}

const TemplateSider: React.FC<TemplateSiderProps> = ({ onSetMessageText }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [visible, setVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const [variableValues, setVariableValues] = useState<Record<string, VariableWithDescription>>({});

  useEffect(() => {
    const userData = getUserDataFromLocalStorage();
    if (userData && userData.templates) {
      const templatesWithUIKey = userData.templates.map((template: Template) => ({
        ...template,
        ui_key: uuidv4(),
      }));
      setTemplates(templatesWithUIKey);
    }
  }, []);

  const handleSave = (updatedTemplate: Template) => {
    const existingTemplate = templates.find(template => template.ui_key === updatedTemplate.ui_key);

    let updatedTemplates;
    if (existingTemplate) {
      updatedTemplates = templates.map(template =>
        template.ui_key === updatedTemplate.ui_key ? updatedTemplate : template
      );
    } else {
      updatedTemplates = [...templates, updatedTemplate];
    }

    setTemplates(updatedTemplates);
    updateUserTemplates(updatedTemplates);
  };

  const handleRemoveTemplate = async (ui_key: string) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this template?',
      content: 'This action cannot be undone',
      onOk: async () => {
        const updatedTemplates = templates.filter(template => template.ui_key !== ui_key);
        setTemplates(updatedTemplates);
        await updateUserTemplates(updatedTemplates);
      }
    });
  };

  const updateUserTemplates = async (updatedTemplates: Template[]) => {
    const userData = getUserDataFromLocalStorage();
    if (userData) {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          throw new Error("No access token available");
        }

        const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/users/update_user_templates`, {
          user_id : userData._id,
          templates: JSON.stringify(updatedTemplates)
        });

        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }

        userData.templates = updatedTemplates;
        setUserDataInLocalStorage(userData);

        return response.data;
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      } finally {
        localStorage.setItem('user', JSON.stringify(userData));
      }
    }
  };


  const openTemplateCreator = (template: Template | null, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setCurrentTemplate(template);
    setVisible(true);
  };

  const handleVariableChange = (variable: string, value: string) => {
    setVariableValues(prev => ({ ...prev, [variable]: { ...prev[variable], value } }));
  };

  const prepareTemplateText = (template: Template) => {
    const dataSource = template.variables.map(item => ({
      key: item.variable,
      variable: item.variable,
      value: variableValues[item.variable]?.value || '',
      placeholder: item.description || '',
    })) || [];

    let newText = template.text ?? '';
    dataSource.forEach(data => {
      const value = data.value || '';
      newText = newText.replace(new RegExp(`{${data.variable}}`, 'g'), value);
    });

    return newText;
  };

  const handleOk = () => {
    const newText = prepareTemplateText(currentTemplate!);
    if (onSetMessageText) {
      onSetMessageText(newText);
    }
    setOverlayVisible(false);
  };

  const handleMenuItemClick = (template: Template, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setCurrentTemplate(template);

    if (template.variables.length === 0) {
      const newText = prepareTemplateText(template);
      if (onSetMessageText) {
        onSetMessageText(newText);
      }
    } else {
      setOverlayVisible(true);
    }
  };

  const renderOverlay = () => {
    const dataSource = currentTemplate?.variables.map(item => ({
      key: item.variable,
      variable: item.variable,
      value: variableValues[item.variable]?.value || '',
      placeholder: item.description || '',
    })) || [];

    const columns = [
      {
        title: 'Variable',
        dataIndex: 'variable',
        key: 'variable',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (text: string, record: any) => (
          <Input
            value={record.value}
            placeholder={record.placeholder}
            onChange={(e) => handleVariableChange(record.variable, e.target.value)}
          />
        ),
      },
    ];

    return (
      <Modal
        title="Set Variable Values"
        open={overlayVisible}
        onOk={handleOk}
        onCancel={() => setOverlayVisible(false)}
        style={{
          minHeight: '80%',
          maxHeight: '80%',
          minWidth: '50%',
          maxWidth: '50%',
          margin: '0 auto',
          top: '17vh'
        }}
      >
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </Modal>
    );
  };

  return (
    <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
      <Sider width={chatSiderWidth}>
        <StyledButton size="large" type="dashed" onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => openTemplateCreator(null, e)}>
          + New Template
        </StyledButton>
        <Menu
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
          selectedKeys={selectedKey ? [selectedKey] : []}>
          {templates.map((template) => (
            <Menu.Item
              key={template.ui_key}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: "nowrap",
                maxWidth: "100%",
                flexDirection: "row",
                alignItems: "center",
                padding: "0px 0px",
                border: "1px solid #FFFFFF1A",
              }}
            >
              <Button
                type="text"
                style={{
                  textAlign: 'left',
                  width: '80%',
                  height: '100%',
                  border: 'none',
                  boxShadow: 'none',
                  color: 'inherit',
                  backgroundColor: 'transparent'
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                onClick={(e) => handleMenuItemClick(template, e)}
              >
                {template.name}
              </Button>
              <Button
                type="text"
                icon={<EditOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  openTemplateCreator(template, e);
                }}
                style={{
                  flexShrink: 0,
                  height: '100vh',
                  border: 'none',
                  padding: 0,
                  width: '10%',
                }}
              />
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveTemplate(template.ui_key);
                }}
                style={{
                  flexShrink: 0,
                  height: '100vh',
                  border: 'none',
                  padding: 0,
                  width: '10%',
                }}
              />
            </Menu.Item>
          ))}
        </Menu>
        {visible && (
          <TemplateCreator
            visible={visible}
            onClose={() => {
              setVisible(false);
              setSelectedKey(null);
            }}
            onSave={handleSave}
            template={currentTemplate}
          />
        )}
        {renderOverlay()}
      </Sider>
    </div>
  );
};

export default TemplateSider;
