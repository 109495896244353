import { Form, Input, Modal, message, theme } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { storeQuickSupport } from '../services/feedback';
import { getUserDataFromLocalStorage } from '../utils/useLocalStorage';
import { DiscordOutlined } from '@ant-design/icons';
import { FlexMarginButton } from './basic/buttons';


// Define the types for the component props
interface QuickQuestionModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface DiscordInviteProps {
  color: string;
}

const DiscordInvite: React.FC<DiscordInviteProps> = ({ color }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between', // Adjusts to space-between to separate text and icon
        // marginTop: '10px',
        width: '100%', // Ensures the container takes full width
        paddingLeft: '20px', // Adds padding around the content
        paddingRight: '20px', // Adds padding around the content
        boxSizing: 'border-box', // Ensures padding does not add to the total width
        border: 'none',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column', // Stacks sentences vertically
        }}
      >
        <div>Want to talk to us directly?</div>
        <div>Join us on Discord:</div>
      </div>
      <div>
        <a
          className="discord-link"
          href="https://discord.gg/yx2AMBHA2d"
          target="_blank" rel="noopener noreferrer"
        >
          <DiscordOutlined className="discord-icon" style={{ fontSize: '100px', color: color }} />
        </a>
      </div>
    </div>
  );
};

const QuickQuestionModal: React.FC<QuickQuestionModalProps> = ({ isOpen, setIsOpen }) => {
  const { token } = theme.useToken();
  const user = getUserDataFromLocalStorage();
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);

  function clearAll() {
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit: (values) => {
      setSubmitLoading(true)
      storeQuickSupport(values.text, user?._id)
        .then(() => {
          Modal.success({
            title: 'We are on it!',
            content: <DiscordInvite color={token.colorPrimaryHover} />,
          });
          setIsOpen(false);
        })
        .catch(() => {
          message.error('Something went wrong!');
        })
        .finally(() => {
          setSubmitLoading(false)
          clearAll();
        })
    },
    validateOnChange: false,
    validateOnBlur: false
  });

  return (
    <Modal
      title={<h3>Infolab's Support Form ⛑️</h3>}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <p><i>Submitting as {user?.name}</i></p>
      <Form onSubmitCapture={formik.handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "40px" }}>
        <div style={{ margin: "20 auto 0 auto", width: "100%", }}>
          <label style={{ fontWeight: 700 }} htmlFor="comments">You can use this form to quickly get in contact with us</label>
          <Form.Item
            help={formik.errors.text}
            validateStatus={formik.errors.text ? "error" : undefined}
          >
            <Input.TextArea
              style={{ marginTop: 10 }}
              size="large"
              id="text"
              placeholder="Your text goes here"
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </div>
        <DiscordInvite color={token.colorPrimaryHover} />
        <FlexMarginButton loading={submitLoading} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>Submit</FlexMarginButton>
      </Form>
    </Modal >
  );
};

export default QuickQuestionModal;
