import axios from 'axios';
import authedAxios from './auth-axios';

export async function fetchUserType(user_id: string): Promise<string>{
    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${user_id}/type`);
        return response.data.type;
    }
    catch (error) {
        console.error('Error fetching user type:', error);
        throw error;
    }
}

type CreditUsage = {
  max_sent_messages: number
  current_sent_messages: number
}

export async function getUsage(user_id: string): Promise<CreditUsage>{
  try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${user_id}/usage`);
      return response.data as CreditUsage;
  }
  catch (error) {
      console.error('Error fetching user type:', error);
      throw error;
  }
}

type TotalStudentUsage = {
  total_student_usage: number
}

export const getTotalStudentUsage = async (user_id: string, assigned_student_id_list: string[]): Promise<TotalStudentUsage> => {
    try {
        const queryParams = new URLSearchParams();
        assigned_student_id_list.forEach(id => queryParams.append('assigned_student_id_list', id));
        const response = await authedAxios.get(
            `${process.env.REACT_APP_BACKEND_URL}/users/${user_id}/student-usage?${queryParams.toString()}`
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching student usage:', error);
        throw error;
    }
};

export const verifyToken = async () => {
  try {
    const token = localStorage.getItem('access_token');
    if (!token) return false;

    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/verify-token`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data.isValid;
  } catch (error) {
    console.error('Token verification failed', error);
    return false;
  }
};

export const update_user_config = async (
  user_id: string, 
  course_ids: string[], 
  organization: string, 
  userNumberCap: number, 
  userMessageCap: number,
  orgMessageCap: number,
  courseTerminology: string,
  moduleTerminology: string,
  sourcesVisible: boolean,
  filterBySource: boolean,
  filterByGroup: boolean,
): Promise<boolean> => {
  try {
    const response = await authedAxios.post(`${process.env.REACT_APP_BACKEND_URL}/users/${user_id}/update-user-config`, {
      auto_enroll_students_to_courses: course_ids,
      organization: organization,
      userNumberCap: userNumberCap,
      userMessageCap: userMessageCap,
      orgMessageCap: orgMessageCap,
      courseTerminology: courseTerminology,
      moduleTerminology: moduleTerminology,
      sourcesVisible: sourcesVisible,
      filterBySource: filterBySource,
      filterByGroup: filterByGroup,
    });
    return response.status === 200;
  } catch (error) {
    console.error('Error updating auto enroll:', error);
    throw error;
  }
}