import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Course, CourseFile, deleteCourse, getCourse, loadCourseFiles, updateCourse } from "../services/courses";
import { getUserDataFromLocalStorage } from "../utils/useLocalStorage";
import { capitalize } from "../utils/utils";

export const useCourse = () => {
    const navigate = useNavigate();
    const { courseID } = useParams();

    const [savedCourse, setSavedCourse] = useState<Course | undefined>(undefined);
    const [tempCourse, setTempCourse] = useState<Course | undefined>(undefined);
    const [courseFiles, setCourseFiles] = useState<CourseFile[]>([]);

    const [courseSavingLoading, setCourseSavingLoading] = useState(false);
    const hasChanges = (tempCourse && savedCourse && JSON.stringify(tempCourse) !== JSON.stringify(savedCourse))!!

    const user = getUserDataFromLocalStorage();
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const latestTimerRef = useRef<Date | null>(null);

    useEffect(() => {
        getCourse(courseID!).then(s => {
            setSavedCourse(JSON.parse(JSON.stringify(s)))
            setTempCourse(JSON.parse(JSON.stringify(s)))
        })
        loadCourseFiles(courseID!).then(setCourseFiles)
    }, [courseID])

    async function saveCourseChanges() {
        if (tempCourse && hasChanges) {
            setCourseSavingLoading(true)
            updateCourse({ ...tempCourse })
                .then((success) => {
                    message.success(`${capitalize(user.config.course_terminology)} updated successfully!`)
                    setSavedCourse(structuredClone(tempCourse));
                })
                .catch((err) => {
                    message.error(`Error updating ${user.config.course_terminology.toLowerCase()}`)
                })
                .finally(() => {
                    setCourseSavingLoading(false)
                }
                )
        }
    }

    async function deleteCurrentCourse() {
        deleteCourse(courseID!).then(() => {
            message.success(`${capitalize(user.config.course_terminology)} deleted successfully!`)
        }).finally(() => {
            navigate("/courses");

        })
    }

    async function fetchCourseFiles (courseId: string) {
        try {
            const courseFiles = await loadCourseFiles(courseId);
            setCourseFiles(courseFiles);
        } catch (error) {
            console.error(`Error fetching ${user.config.course_terminology.toLowerCase()} files:`, error);
        }
    }

    async function checkFileStatusAfterUpload (uploadTimer: Date) {
        // Fetch course files
        fetchCourseFiles(courseID!);

        const intervalId = setInterval(async () => {
            const currentFiles = await loadCourseFiles(courseID!);
            if (JSON.stringify(currentFiles) !== JSON.stringify(courseFiles)) {
                setCourseFiles(currentFiles);
            }

            // if uploadTimer is set and current time is greater than uploadTimer
            if (uploadTimer && Date.now() > uploadTimer.getTime()) {
                clearInterval(intervalId);
            }

        }, 5000); // Check every 5 second

        return () => clearInterval(intervalId); // Cleanup on unmount
    }

    async function reloadCoursePeriodically(uploadTimer: Date) {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        if (!latestTimerRef.current || uploadTimer > latestTimerRef.current) {
            latestTimerRef.current = uploadTimer;
        }

        const checkAndUpdateCourse = async () => {
            try {
                const newCourse = await getCourse(courseID!);
                if (JSON.stringify(newCourse) !== JSON.stringify(tempCourse)) {
                    setSavedCourse(JSON.parse(JSON.stringify(newCourse)));
                    setTempCourse(JSON.parse(JSON.stringify(newCourse)));
                    await loadCourseFiles(courseID!).then(setCourseFiles);
                }

                if (latestTimerRef.current && Date.now() > latestTimerRef.current.getTime()) {
                    clearInterval(timerRef.current!);
                    timerRef.current = null;
                    latestTimerRef.current = null;
                }
            } catch (error) {
                console.error("Error reloading course:", error);
            }
        };

        timerRef.current = setInterval(checkAndUpdateCourse, 5000); // Check every 5 seconds

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }

    return { 
        tempCourse, 
        setTempCourse, 
        saveCourseChanges, 
        deleteCurrentCourse, 
        courseSavingLoading, 
        hasChanges, 
        courseFiles, 
        setCourseFiles, 
        fetchCourseFiles, 
        checkFileStatusAfterUpload, 
        reloadCoursePeriodically 
    };
}