import { DeleteOutlined, DownOutlined, UpOutlined, MinusOutlined, PlusOutlined, FunnelPlotOutlined } from "@ant-design/icons";
import { styled } from "@stitches/react";
import { Checkbox, Input, Table, Tag, InputRef, Row, DatePicker, message, theme } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { CourseContext } from "../../screens/courses/course";
import { WeekMaterial, summarizeWeek } from "../../services/courses";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { audioFormats, noPageFormats } from "../../config";
import { FlexMarginButton } from "../basic/buttons";
import { capitalize, hexToRGBA } from "../../utils/utils";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";

const { RangePicker } = DatePicker;

const WeekContainer = styled('div', {
    // backgroundColor: 'rgba(255,255,255,0.12)',
    margin: '1rem 0',
    padding: "1rem 2rem",
    borderRadius: '8px',
    // border: "1px dashed rgba(255,255,255,0.3)",

})

export const Weeks = () => {
    const user = getUserDataFromLocalStorage();
    const { token } = theme.useToken();
    const { tempCourse: course, setTempCourse, courseFiles, reloadCoursePeriodically } = useContext(CourseContext)
    const [isSettingAssignment, setIsSettingAssignment] = useState<boolean[]>([]);
    const [isModifyingSummary, setIsModifyingSummary] = useState<boolean[]>([]);
    const [uploadTimer, setUploadTimer] = useState(new Date());
    const [isAddingMaterial, setIsAddingMaterial] = useState<boolean[]>([]);

    useEffect(() => {
        if (uploadTimer) {
            reloadCoursePeriodically(uploadTimer);
        }
    }, [uploadTimer]);

    useEffect(() => {
        setTempCourse((prevCourse) => {
            if (!prevCourse) return prevCourse;
            const updatedWeeks = prevCourse.weeks.map((week) => ({
                ...week,
                materials: week.materials.filter((material) =>
                    courseFiles.some((file) => file._id === material.file_id)
                ),
            }));
            return { ...prevCourse, weeks: updatedWeeks };
        });
    }, [courseFiles, setTempCourse]);

    function toggleSetAssignment(weekIndex: number) {
        setIsModifyingSummary(prev => {
            const newIsModifyingSummary = [...prev];
            newIsModifyingSummary[weekIndex] = false;
            return newIsModifyingSummary;
        });
        setIsSettingAssignment(prev => {
            const newIsSettingAssignment = [...prev];
            newIsSettingAssignment[weekIndex] = !newIsSettingAssignment[weekIndex];
            return newIsSettingAssignment;
        });
    }

    function handleSummarizeWeek(weekIndex: number) {
        if (course!.weeks[weekIndex].summary === '') {
            summarizeWeek(course!._id, weekIndex + 1).then(() => message.success('Hang on, the summary is being generated!'))
            // set uploadTimer to now + 10 min
            setUploadTimer(new Date(Date.now() + 10 * 60000));
        }
        else {
            setIsSettingAssignment(prev => {
                const newIsSettingAssignment = [...prev];
                newIsSettingAssignment[weekIndex] = false;
                return newIsSettingAssignment;
            });
            setIsModifyingSummary(prev => {
                const newIsModifyingSummary = [...prev];
                newIsModifyingSummary[weekIndex] = !newIsModifyingSummary[weekIndex];
                return newIsModifyingSummary;
            });
        }
    }

    function toggleAddMaterial(weekIndex: number) {
        setIsAddingMaterial(prev => {
            const newIsAddingMaterial = [...prev];
            newIsAddingMaterial[weekIndex] = !newIsAddingMaterial[weekIndex];
            return newIsAddingMaterial;
        });
    }

    function createWeek() {
        setTempCourse(course => {
            const courseWeeks = [...(course?.weeks || [])]
            courseWeeks.push(
                {
                    number: courseWeeks.length + 1,
                    materials: [],
                    topics: [],
                    assignment: '',
                    start_date: new Date().toISOString(),
                    end_date: dayjs(new Date()).add(7, 'day').toISOString(),
                    summary: ''
                }
            )
            return { ...course!, weeks: courseWeeks }
        }
        )
    }

    function removeLastWeek() {
        setTempCourse(course => {
            const newWeeks = course?.weeks.slice(0, -1);
            return { ...course!, weeks: newWeeks! };
        });
    }

    function convertTimeToSeconds(timeString: string | number | undefined): number {
        if (typeof timeString === 'number') return timeString;
        if (!timeString) return 0; // Return 0 instead of undefined

        const parts = timeString.split(':');
        if (parts.length !== 2) return Number(timeString); // Invalid format, return 0

        const [minutes, seconds] = parts.map(Number);
        if (isNaN(minutes) || isNaN(seconds)) return 0; // Invalid numbers, return 0

        return minutes * 60 + seconds;
    }

    function convertSecondsToTime(seconds: number | undefined): string {
        if (seconds === undefined) return '';
        if (seconds === 0) return '0:00';
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    function changeWeekMaterial(weekIndex: number, file_id: string, change: Partial<WeekMaterial>) {
        setTempCourse(course => {
            const newWeeks = course!.weeks;
            const week = newWeeks[weekIndex];
            const materialIndex = week.materials.findIndex(m => m.file_id === file_id);
            const material = { ...week.materials[materialIndex] };
            newWeeks[weekIndex].materials[materialIndex] = { ...material, ...change };
            return { ...course!, weeks: newWeeks };
        });
    }


    function addMaterialToWeek(fileId: string, weekIndex: number) {
        if (course!.weeks[weekIndex].materials.map(m => m.file_id).includes(fileId)) {
            return
        }
        setTempCourse(course => {
            const newWeeks = [...course!.weeks];
            const newMaterials = [...newWeeks[weekIndex].materials];
            const file = courseFiles.find(f => f._id === fileId);
            if (!audioFormats.some(ext => file!.file_name.endsWith(ext))) {
                newMaterials.push({
                    name: file!.file_name,
                    file_id: fileId,
                    spans_fully: true,
                    span_start: 1,  // page numbers start with 1
                    span_end: file!.number_of_pages,
                    file_type: 'book',
                    group: ''
                });
            } else {
                newMaterials.push({
                    name: file!.file_name,
                    file_id: fileId,
                    spans_fully: true,
                    span_start: 1,  // page numbers for audio files are seconds
                    span_end: file!.number_of_pages,
                    file_type: 'audio',
                    group: ''
                });
            }
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], materials: newMaterials };
            return { ...course!, weeks: newWeeks };
        })
    }

    function handleSetAssignment(weekIndex: number, value: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse; // Exit early if no course is available
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                assignment: value
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
    }

    function handleModifyingSummary(weekIndex: number, value: string) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse; // Exit early if no course is available
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                summary: value
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
        if (value === '') {
            setIsModifyingSummary(prev => {
                const newIsModifyingSummary = [...prev];
                newIsModifyingSummary[weekIndex] = false;
                return newIsModifyingSummary;
            });
        }
    }

    function handleSetStartEndDate(weekIndex: number, dates: Dayjs[]) {
        if (!dates) return;
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse;
            const newWeeks = [...prevCourse.weeks];
            const updatedWeek = {
                ...newWeeks[weekIndex],
                start_date: dates[0].toISOString(),
                end_date: dates[1].toISOString()
            };
            newWeeks[weekIndex] = updatedWeek;
            return {
                ...prevCourse,
                weeks: newWeeks
            };
        });
    }


    function removeMaterialFromWeek(fileId: string, weekIndex: number) {
        setTempCourse(course => {
            const newWeeks = [...course!.weeks];
            const newMaterials = newWeeks[weekIndex].materials.filter(m => m.file_id !== fileId);
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], materials: newMaterials };
            return { ...course!, weeks: newWeeks };
        })
    }

    function handleSetTopics(weekIndex: number, newTopics: string[]) {
        setTempCourse(prevCourse => {
            if (!prevCourse) return prevCourse; // Exit early if no course is available
            const newWeeks = [...prevCourse.weeks];
            newWeeks[weekIndex] = { ...newWeeks[weekIndex], topics: newTopics };
            return { ...prevCourse, weeks: newWeeks };
        });
    };

    const handleClose = (removedTag: string, weekIndex: number) => {
        const newTopics = course!.weeks[weekIndex].topics.filter(tag => tag !== removedTag);
        handleSetTopics(weekIndex, newTopics);
    };

    const EditableTagGroup = ({ weekIndex }: { weekIndex: number }) => {
        const [inputVisible, setInputVisible] = useState(false);
        const [inputValue, setInputValue] = useState('');
        const inputRef = useRef<InputRef>(null);

        useEffect(() => {
            inputVisible && inputRef.current?.focus();
        }, [inputVisible]);

        const showInput = () => {
            setInputVisible(true);
        };

        const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
        };

        const handleInputConfirm = () => {
            if (inputValue && course!.weeks[weekIndex].topics.indexOf(inputValue) === -1) {
                const newTopics = [...course!.weeks[weekIndex].topics, inputValue];
                handleSetTopics(weekIndex, newTopics);
            }
            setInputVisible(false);
            setInputValue('');
        };

        return (
            <>
                {course!.weeks[weekIndex].topics.map(tag => (
                    <Tag
                        closable
                        onClose={() => handleClose(tag, weekIndex)}
                        key={tag}
                    >
                        {tag}
                    </Tag>
                ))}
                {inputVisible ? (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                ) : (
                    <Tag onClick={showInput} style={{ borderStyle: 'dashed' }}>
                        <PlusOutlined /> New Tag
                    </Tag>
                )}
            </>
        );
    };



    return (<>
        {course!.weeks.map((week, weekIndex) => {
            return (<>
                <WeekContainer style={{ backgroundColor: hexToRGBA(token.colorBgElevated, 1) }}>
                    <h2>{`📝 ${capitalize(user.config.module_terminology)} ${weekIndex + 1}`}</h2>
                    <Row>
                        <FlexMarginButton
                            data-testid={`toggle-add-material-button-${weekIndex}`}
                            icon={isAddingMaterial[weekIndex] ? <UpOutlined /> : <DownOutlined />}
                            onClick={() => toggleAddMaterial(weekIndex)}
                        >
                            Add Material
                        </FlexMarginButton>
                        <FlexMarginButton
                            data-testid={`set-assignment-button-${weekIndex}`}
                            icon={isSettingAssignment[weekIndex] ? <UpOutlined /> : <DownOutlined />}
                            onClick={() => toggleSetAssignment(weekIndex)}
                        >
                            Set Assignment Message
                        </FlexMarginButton>
                        <FlexMarginButton
                            data-testid={`summarize-week-button-${weekIndex}`}
                            icon={course!.weeks[weekIndex].summary === '' ? <FunnelPlotOutlined /> : isModifyingSummary[weekIndex] ? <UpOutlined /> : <DownOutlined />}
                            onClick={() => { handleSummarizeWeek(weekIndex) }}
                        >
                            {course!.weeks[weekIndex].summary === '' ? 'Summarize Week' :
                                course!.weeks[weekIndex].summary.includes('Summarizing') ? 'Summarizing' :
                                    'Summary Generated'}
                        </FlexMarginButton>
                    </Row>
                    {isSettingAssignment[weekIndex] && (
                        <>
                            <Input.TextArea
                                rows={10}
                                value={course!.weeks[weekIndex].assignment}
                                onChange={(e) => handleSetAssignment(weekIndex, e.target.value)}
                                placeholder={`This ${user.config.module_terminology.toLowerCase()}'s assignments are...`}
                                style={{ marginTop: '5px' }}
                            />
                        </>
                    )}
                    {isModifyingSummary[weekIndex] && (
                        <>
                            <Input.TextArea
                                rows={10}
                                value={course!.weeks[weekIndex].summary}
                                onChange={(e) => handleModifyingSummary(weekIndex, e.target.value)}
                                style={{ marginTop: '5px' }}
                            />
                        </>
                    )}
                    {isAddingMaterial[weekIndex] && (
                        <div>
                            {courseFiles.filter(
                                f => !course!.weeks[weekIndex].materials.map(
                                    m => m.file_id).includes(f._id)).map(
                                        f => <FlexMarginButton
                                            data-testid={`add-material-${f.file_name}-to-week-${weekIndex}`}
                                            size="large"
                                            icon={<PlusOutlined />}
                                            onClick={() => addMaterialToWeek(f._id, weekIndex)}>{f.file_name}
                                        </FlexMarginButton>)
                            }
                        </div>
                    )}
                    <h3>Materials</h3>
                    <Table
                        dataSource={week.materials}
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: "All Pages?",
                                dataIndex: 'spans_fully',
                                key: 'spans_fully',
                                render: (_: any, record: WeekMaterial, materialIndex: number) => (
                                    <Checkbox
                                        data-testid={`spans-fully-checkbox-${materialIndex}-week-${weekIndex}`}
                                        checked={week.materials[materialIndex].spans_fully}
                                        onChange={e => changeWeekMaterial(weekIndex, record.file_id,
                                            { spans_fully: Boolean(e.target.checked), span_start: 1,  span_end: courseFiles.find(f => f._id === record.file_id)!.number_of_pages })}
                                        disabled={noPageFormats.some(format => week.materials[materialIndex].name.endsWith(format))} />
                                ),
                            },
                            {
                                title: "First Relevant Page / Time",
                                dataIndex: 'span_start',
                                key: 'span_start',
                                render: (_: any, record: WeekMaterial, materialIndex: number) => (
                                    <Input
                                        data-testid={`span-start-input-${materialIndex}-week-${weekIndex}`}
                                        disabled={week.materials[materialIndex].spans_fully}
                                        value={record.file_type === 'audio'
                                            ? convertSecondsToTime(week.materials[materialIndex].span_start)
                                            : week.materials[materialIndex].span_start?.toString() || ''}
                                        onChange={e => changeWeekMaterial(weekIndex, record.file_id, { span_start: convertTimeToSeconds(e.target.value) })}
                                        placeholder={record.file_type === 'audio' ? "Minute : Second (mm:ss)" : "Page number"}
                                    />
                                ),
                            },
                            {
                                title: "Last Relevant Page / Time",
                                dataIndex: 'span_end',
                                key: 'span_end',
                                render: (_: any, record: WeekMaterial, materialIndex: number) => (
                                    <Input
                                        data-testid={`span-end-input-${materialIndex}-week-${weekIndex}`}
                                        disabled={week.materials[materialIndex].spans_fully}
                                        value={record.file_type === 'audio'
                                            ? convertSecondsToTime(week.materials[materialIndex].span_end)
                                            : week.materials[materialIndex].span_end?.toString() || ''}
                                        onChange={e => changeWeekMaterial(weekIndex, record.file_id, { span_end: convertTimeToSeconds(e.target.value) })}
                                        placeholder={record.file_type === 'audio' ? "MM:SS" : "Page number"}
                                    />
                                )
                            },
                            {
                                title: "Group",
                                dataIndex: 'group',
                                key: 'group',
                                render: (_: any, record: WeekMaterial, materialIndex: number) => (
                                    <Input
                                        data-testid={`group-input-${materialIndex}-week-${weekIndex}`}
                                        value={week.materials[materialIndex].group || ''}
                                        onChange={e => changeWeekMaterial(weekIndex, record.file_id, { group: e.target.value })}
                                        placeholder={''}
                                    />
                                )
                            },
                            {
                                title: '',
                                dataIndex: 'delete',
                                key: 'delete',
                                render: (_: any, record: WeekMaterial) => (
                                    <FlexMarginButton
                                        data-testid="delete-button"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            removeMaterialFromWeek(record.file_id, weekIndex)
                                        }}
                                    />
                                ),
                            },
                        ]} />
                    <h3>Duration</h3>
                    <Row>
                        <RangePicker
                            value={[dayjs(week.start_date, 'YYYY-MM-DD'), dayjs(week.end_date, 'YYYY-MM-DD')]}
                            onChange={(dates) => handleSetStartEndDate(weekIndex, dates as Dayjs[])}
                        />
                    </Row>
                    <h3>Key Topics</h3>
                    <EditableTagGroup weekIndex={weekIndex} />
                </WeekContainer>
            </>)
        })}
        <FlexMarginButton
            size="large"
            icon={<PlusOutlined />}
            onClick={() => createWeek()}>
            {`Add ${capitalize(user.config.module_terminology)} ${course!.weeks.length + 1}`}
        </FlexMarginButton>
        {course!.weeks.length > 0 &&
            <FlexMarginButton
                size="large"
                icon={<MinusOutlined />}
                onClick={() => removeLastWeek()}>
                {`Remove ${capitalize(user.config.module_terminology)} ${course!.weeks.length}`}
            </FlexMarginButton>}
    </>)
}
