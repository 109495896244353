import { DeleteOutlined, MessageOutlined } from "@ant-design/icons";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { Layout, Popconfirm, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Conversation, analytics_chat_label, deleteConversation, loadConversations } from '../../services/conversations';
import { Course, getUserCourses } from "../../services/courses";
import { getUserDataFromLocalStorage } from "../../utils/useLocalStorage";
import { capitalize, formatDate } from "../../utils/utils";
import { NoMarginButton } from "../../components/basic/buttons";
const { Content } = Layout;

const ChatHistory = () => {
    const user = getUserDataFromLocalStorage();
    const [chatHistory, setChatHistory] = useState<Conversation[]>([]);
    const [courses, setCourses] = useState<Course[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    async function fetchChatHistory(currentCourses: Course[]) {
        const conversations = await loadConversations(user._id);
        const filteredConversations = conversations.filter(conversation => 
            currentCourses.some(course => course._id === conversation.course_id)
        );
        setChatHistory(filteredConversations);
    }

    useEffect(() => {
        setIsLoading(true);
        getUserCourses(user._id)
            .then(fetchedCourses => {
                setCourses(fetchedCourses);
                return fetchedCourses;
            })
            .then(fetchedCourses => fetchChatHistory(fetchedCourses))
            .then(() => setIsLoading(false));
    }, []);

    function handleChatDelete(conversationId: string) {
        deleteConversation(conversationId).then(() => {
            setChatHistory(prev => prev.filter(c => c._id !== conversationId))
            message.success('Chat deleted successfully.');
        })
    }


    return (
        <Content style={{ padding: "2rem" }}>
            {isLoading
                ? <Spin size="large" />
                :
                <Table>
                    <TableHeader>
                        <TableColumn style={{ flex: 0, textAlign: 'left', fontSize: '1rem' }}>Date</TableColumn>
                        <TableColumn style={{ flex: 1, textAlign: 'left', fontSize: '1rem' }}>Name</TableColumn>
                        <TableColumn style={{ flex: 1, textAlign: 'left', fontSize: '1rem' }}>Name</TableColumn>
                        <TableColumn style={{ flex: 1, textAlign: 'left', fontSize: '1rem' }}>{`${capitalize(user.config.course_terminology)} Description`}</TableColumn>
                        <TableColumn style={{ flex: 0, textAlign: 'left', fontSize: '1rem' }}>Type</TableColumn>
                        <TableColumn style={{ flex: 0, textAlign: 'left', fontSize: '1rem' }}>Length</TableColumn>
                        <TableColumn style={{ flex: 0, textAlign: 'center', fontSize: '1rem' }}>Actions</TableColumn>
                    </TableHeader>
                    <TableBody
                        items={chatHistory.sort((a, b) => new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime())}
                        isLoading={isLoading}
                        loadingContent={<Spin size="large" />}
                    >
                        {(item) => {
                            const course = courses.find(s => s._id === item.course_id);
                            return (
                                <TableRow key={item._id}>
                                    <TableCell style={{ flex: 0 }}>{formatDate(item.last_updated)}</TableCell>
                                    <TableCell style={{ flex: 1 }}>{item.name}</TableCell>
                                    <TableCell style={{ flex: 1 }}>{course!.name}</TableCell>
                                    <TableCell style={{ flex: 1 }}>{course!.description.substring(0, 75)}</TableCell>
                                    <TableCell style={{ flex: 1 }}>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</TableCell>
                                    <TableCell style={{ flex: 1 }}>{item.messages.length}</TableCell>
                                    <TableCell style={{ flex: 0, textAlign: 'center' }}>
                                        <Popconfirm
                                            title="Delete the chat"
                                            description={<p>Are you sure to delete this chat?<br />This action is irreversible</p>}
                                            onConfirm={() => handleChatDelete(item._id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <NoMarginButton icon={<DeleteOutlined />}></NoMarginButton>
                                        </Popconfirm>
                                        <NoMarginButton
                                            onClick={() => navigate(item.type === analytics_chat_label ? `/analytics-copilot/${item._id}` : `/chat/${item._id}`)}
                                            icon={<MessageOutlined />}
                                            style={{ marginLeft: "10px" }}
                                            >
                                            Resume
                                        </NoMarginButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }}
                    </TableBody>
                </Table>
            }
        </Content>
    );
};



export default ChatHistory;
